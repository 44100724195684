import { Stack } from '@mui/material';

import { FilterSummary } from '@hapvida/hapvida-core-components';

import { BackofficeUserListCard } from './components';
import { useBackofficeUserState } from './hooks';

export function BackofficeUserListLayout() {
  const { backofficeUserState } = useBackofficeUserState();

  const totalUsersLength = backofficeUserState?.data
    ? backofficeUserState?.data?.total
    : 0;

  const filterSummaryLabel = `${totalUsersLength} ${
    totalUsersLength === 1 ? 'usuário' : 'usuários'
  }`;

  return (
    <Stack paddingY={3} gap={3}>
      <FilterSummary totalLabel={filterSummaryLabel} />
      <BackofficeUserListCard />
    </Stack>
  );
}
