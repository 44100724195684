import { Stack } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import { Loading } from '@hapvida/hapvida-core-components';

import { CollapseNew } from '@hapvida/hapvida-core-components/src/components/TableBodies/CollapseNew';

import { EmptyResult } from '@components';
import { useFilterParams, useAuth } from '@hooks';
import { ITEMS_LIMIT_PER_PAGE } from '@constants';
import { BackofficeUserDetailsRouter } from '@flows/private/backofficeUser/pages/BackofficeUserDetails/router';
import { useBackofficeUserState } from '@flows/private/backofficeUser/pages/BackofficeUserList/hooks/useBackofficeUserState';

import { ErrorLoading } from '../ErrorLoading';
import { backofficeUserListTableHeadColumns } from '../../constants';
import { useUserBackofficeListTableRows } from './hooks/useUserBackofficeListTableRows';

export function BackofficeUserListCardTable() {
  const { handleTableOrderChange, handlePageChange, currentPage, tableOrder } =
    useFilterParams();
  const { backofficeUserState, refreshBackofficeUserList } =
    useBackofficeUserState();
  const { user: loggedUser } = useAuth();

  const { userBackofficeListTableRows } = useUserBackofficeListTableRows({
    users: backofficeUserState.data?.users,
  });

  const navigate = useNavigate();

  const handleChangePage = (_event: unknown, newPage: number) => {
    handlePageChange(newPage);
  };

  const handleEditUser = (id: string) => {
    navigate(BackofficeUserDetailsRouter.dynamicPath({ id }));
  };

  if (backofficeUserState.isLoading) {
    return (
      <Loading
        padding={5}
        message={
          <>
            Estamos carregando as informações. <br /> Em breve, tudo estará
            pronto para você
          </>
        }
      />
    );
  }

  if (backofficeUserState.isError) {
    return <ErrorLoading onReloadClick={refreshBackofficeUserList} />;
  }

  const totalUsersLength = backofficeUserState.data.total ?? 0;
  const isEmptyUsers = totalUsersLength === 0;

  if (isEmptyUsers) {
    return (
      <EmptyResult
        message={`${
          loggedUser?.name ?? ''
        }, você ainda não tem nenhum usuário backoffice cadastrado.`}
      />
    );
  }

  const viewedUsersLength =
    ITEMS_LIMIT_PER_PAGE * (currentPage - 1) +
    backofficeUserState.data.totalPerPage;
  const hasMultiPages = totalUsersLength > ITEMS_LIMIT_PER_PAGE;

  return (
    <Stack>
      <CollapseNew
        TableHeadColumns={backofficeUserListTableHeadColumns}
        count={Math.ceil(totalUsersLength / ITEMS_LIMIT_PER_PAGE)}
        onOrderChange={handleTableOrderChange}
        onPageChange={handleChangePage}
        showFooter={hasMultiPages}
        currentPage={currentPage}
        data={userBackofficeListTableRows}
        from={viewedUsersLength}
        onEdit={handleEditUser}
        tableOrder={tableOrder}
        to={totalUsersLength}
        baseTableProps={{
          tableContainerHeight: '100%',
          style: {
            padding: 0,
          },
        }}
        hasCellAction
      />
    </Stack>
  );
}
