const COLORS = {
  PRIMARY: {
    BLUE: {
      MAIN: '#0054B8',
      '+1': '#003D85',
      '-1': '#2568B8',
      '-2': '#4A7CB8',
      '-3': '#00A1CF',
    },
    GREEN: {
      MAIN: '',
      '+1': '#52872C',
    },
    YELLOW: {
      MAIN: '',
    },
    ORANGE: {
      MAIN: '#F49E00',
      '+1': '#F47920',
      '+2': '#F15A22',
      '-1': '#F1903B',
      '-2': '#F9D0AC',
      '-3': '#F5821F',
    },
    RED: {
      MAIN: '',
      '+1': '#962800',
    },
  },
  SECONDARY: {
    BLUE: {
      MAIN: '#00A1CF',
      '+1': '#0E82A3',
      '-1': '#1976D214',
      '-2': 'rgba(0, 84, 184, 0.04)',
    },
    GREEN: {
      MAIN: '#4BA907',
      LIGHT: '#2E7D32',
      '+1': '#52872C',
      '-1': '#2E7D321F',
      '-2': '#478D52',
    },
    YELLOW: {
      MAIN: '#FFE200',
      '+1': '#CCB500',
      '-1': '#FFD400',
    },
    ORANGE: {
      MAIN: '',
    },
    RED: {
      MAIN: '#C93600',
      '+1': '#962800',
      '-1': '#C95328',
      '-2': '#96280014',
      '-3': '#D32F2F0A',
    },
    PURPLE: {
      MAIN: '#7D20F4',
    },
  },

  BLUE: {
    DARK: '#3A3A54',
  },

  ERROR: {
    MAIN: '#D32F2F',
    LIGHT: '#D32F2F1F',
    DARK: '#541313',
  },

  MONOCHROMATIC: {
    GRAY1: '#F8F9FA',
    GRAY2: '#DDE2E5',
    GRAY3: '#ACB5BD',
    GRAY4: '#495057',
    GRAY5: '#21212114',
    GRAY6: '#575E644D',
    GRAY7: '#FDF7F5',
    GRAY8: '#EEEFF0',
  },
  LINEAR_GRADIENT: {
    GRAY1:
      'linear-gradient(0deg, rgba(244, 158, 0, 0.12), rgba(244, 158, 0, 0.12)), #FFFFFF',
    GRAY2:
      'linear-gradient(0deg, rgba(201, 54, 0, 0.04), rgba(201, 54, 0, 0.04)), #FFFFFF',
    GRAY3:
      'linear-gradient(0deg, rgba(244, 158, 0, 0.04), rgba(244, 158, 0, 0.04)), #FFFFFF',
  },
  WHITE: '#fff',
  BLACK: '#000',
  LIGHT: {
    MAIN: '#FEFEFE',
  },
  DARK: {
    MAIN: '#212429',
  },
  FONT_BLACK: '#3C3C3C',
  DISABLED: {
    BACKGROUND: '#F8F9FA',
    BORDER: '#DDE2E5',
  },
};

const CLASSIC_THEME = {
  CARD_BORDERS: COLORS.MONOCHROMATIC.GRAY3,
  LINE_DIVIDER: COLORS.MONOCHROMATIC.GRAY3,
};

const DARK_THEME = {
  CARD_BORDERS: COLORS.MONOCHROMATIC.GRAY4,
  LINE_DIVIDER: COLORS.MONOCHROMATIC.GRAY3,
};

export { COLORS, CLASSIC_THEME, DARK_THEME };
