import { DrawerFilterProvider } from '@hapvida/hapvida-core-components';

import { BackofficeUserListLayout } from './layout';
// import { UserBackofficeListSubHeader } from './components/UserBackofficeListSubHeader';

export function BackofficeUserListPage() {
  return (
    <DrawerFilterProvider>
      {/* REMINDER: Melhoria futura para receber filtros quando o back implementar retorno de filtros no endpoint
      <UserBackofficeListSubHeader /> */}
      <BackofficeUserListLayout />
    </DrawerFilterProvider>
  );
}
